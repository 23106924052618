// export const BASE_URL = "http://44.204.229.51:3001/";
export const BASE_URL = "https://accel.indiitserver.in:3001/";

export const PIC_URL = "https://accel.indiitserver.in:3001/server/";

// export const BASE_URL = "https://api.accel-ec.com:3001/";
// export const PIC_URL = "https://api.accel-ec.com:3001/server/";


// export const BASE_URL = "http://localhost:3001/";
// export const PIC_URL = "http://localhost:3001/server/";

    